<template>
  <validation-observer ref="clientGroupForm" #default="{ invalid }">
    <div>
      <h4 class="settings-card-title">
        {{ title }}
      </h4>
      <b-card no-body class="px-2 py-2">
        <b-form-group>
          <validation-provider
            #default="{ errors }"
            name="Enable Password Complexity Check"
            rules=""
          >
            <b-form-checkbox
              id="enable_password_complexity_check"
              v-model="settingsData.enable_password_complexity_check"
              name="enable_password_complexity_check"
            >
              Enable Password Complexity Check
            </b-form-checkbox>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-col
          v-if="settingsData.enable_password_complexity_check"
          class="ml-4"
        >
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="Use at least one uppercase and one lowercase letter"
              rules=""
            >
              <b-form-checkbox
                id="include_upper_and_lower_cased_characters"
                v-model="settingsData.include_upper_and_lower_cased_characters"
                name="include_upper_and_lower_cased_characters"
              >
                Use at least one uppercase and one lowercase letter
              </b-form-checkbox>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="Include at least one number"
              rules=""
            >
              <b-form-checkbox
                id="include_number"
                v-model="settingsData.include_number"
                name="include_number"
              >
                Include at least one number
              </b-form-checkbox>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="Include at least one special charater like @, $, !, etc."
            >
              <b-form-checkbox
                id="include_special_character"
                v-model="settingsData.include_special_character"
                name="include_special_character"
              >
                Include at least one special charater like @, $, !, etc.
              </b-form-checkbox>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Minimum Length" label-for="minimum_length">
            <validation-provider
              #default="{ errors }"
              name="Minimum Length"
              rules="required|integer|min_value:5"
            >
              <b-form-input
                id="minimum_length"
                v-model="settingsData.minimum_length"
                :state="errors.length > 0 ? false : null"
                size="sm"
              />
              <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        </b-col>
        <div>
          <b-button
            class="mt-2"
            variant="primary"
            size="lg"
            :disabled="invalid"
            @click="$emit('update', { password_complexity_settings: settingsData })"
          >
            Apply
          </b-button>
        </div>
      </b-card>
    </div>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";

import {
  BOverlay,
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BCardText,
  BFormTextarea,
  BFormCheckbox,
  BFormRadio,
  BFormGroup,
  BPopover,
  VBToggle,
  BFormSpinbutton,
  BFormFile,
  BForm,
  BFormInput,
  BLink,
  BInputGroup,
} from "bootstrap-vue";

export default {
  components: {
    BOverlay,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BPopover,
    vSelect,
    BFormTextarea,
    BFormCheckbox,
    BFormRadio,
    BFormGroup,
    BFormSpinbutton,
    BFormFile,
    BForm,
    BFormInput,
    BLink,
    BInputGroup,

    // validations
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  mixins: [heightTransition],
  props: {
    title: {
      type: String,
      default: "Password Complexity Settings",
    },
    passwordComplexitySettings: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },
  data() {
    return {
      settingsData: {
        minimum_length: 8,
        include_upper_and_lower_cased_characters: true,
        include_number: true,
        include_special_character: false,
        enable_password_complexity_check: false,
      },
    };
  },
  watch: {
    passwordComplexitySettings: {
      handler(setting) {
        this.settingsData = setting
      },
      immediate: true
    }
  }
};
</script>

<style lang="scss" scoped>
.settings-card-title {
  font-weight: 400;
  font-size: 20px;
  line-height: 160%;

  color: #888b97;
}

input {
  width: 150px;
}
</style>
