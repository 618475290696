<template>
<validation-observer ref="clientGroupForm" #default="{invalid}">
  <div>
    <h4 class="settings-card-title">
      {{ title }}
    </h4>
    <b-card no-body class="px-2 py-2">
      <b-form-group label-cols-sm="4" label-cols-lg="4" content-col-sm content-cols-lg-7>
        <validation-provider #default="{ errors }" name="Enable Maintenance Mode" rules="">
          <b-form-checkbox id="enable_maintenance_mode" v-model="settingsData.enable_maintenance_mode" name="enable_maintenance_mode">
            Enable Maintenance Mode
          </b-form-checkbox>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <b-form-group label-cols-sm="4" label-cols-lg="4" content-col-sm content-cols-lg-7>
        <validation-provider #default="{ errors }" name="Disable New SignUps" rules="">
          <b-form-checkbox id="disable_new_signups" v-model="settingsData.disable_new_signups" name="disable_new_signups">
            Disable New Sign-Ups
          </b-form-checkbox>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <b-form-group label-cols-sm="4" label-cols-lg="4" content-col-sm content-cols-lg-7>
        <validation-provider #default="{ errors }" name="Enable Migrations" rules="">
          <b-form-checkbox id="enable_migrations" v-model="settingsData.enable_migrations" name="enable_migrations">
            Enable Migrations
          </b-form-checkbox>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <div>
        <b-button variant="primary" size="lg" :disabled="invalid" @click="$emit('update', { general_settings: settingsData })">
          Apply
        </b-button>
      </div>
    </b-card>
  </div>
</validation-observer>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver
} from 'vee-validate'
import {
  heightTransition
} from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

import {
  BOverlay,
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BCardText,
  BFormTextarea,
  BFormCheckbox,
  BFormRadio,
  BFormGroup,
  BPopover,
  VBToggle,
  BFormSpinbutton,
  BFormFile,
  BForm,
  BFormInput,
  BLink,
  BInputGroup,
  VBTooltip,
} from 'bootstrap-vue'

export default {
  components: {
    BOverlay,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BPopover,
    vSelect,
    BFormTextarea,
    BFormCheckbox,
    BFormRadio,
    BFormGroup,
    BFormSpinbutton,
    BFormFile,
    BForm,
    BFormInput,
    BLink,
    BInputGroup,

    // validations
    ValidationProvider,
    ValidationObserver
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip
  },
  mixins: [heightTransition],
  props: {
    title: {
      type: String,
      default: 'General Settings'
    },
    generalSettings: {
      type: Object,
      default: () => ({}),
      required: true
    },
  },
  data() {
    return {
      settingsData: {
        loan_monthly_rate: 20
      }
    }
  },
  watch: {
    generalSettings: {
      handler(setting) {
        this.settingsData = setting
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.settings-card-title {
  font-weight: 400;
  font-size: 20px;
  line-height: 160%;

  color: #888B97;
}

input {
  width: 150px;
}
</style>
