<template>
  <!-- <b-card no-body> -->
    <b-tabs>
      <b-tab active>
        <template #title>
          <feather-icon icon="ToolIcon" />
          <span>Administrative Settings</span>
        </template>

        <general-settings-form />
      </b-tab>
    </b-tabs>
</template>

<script>
import { BCard, BTabs, BTab, BCardText } from 'bootstrap-vue';

import GeneralSettingsForm from "@/@core/components/shared/admin/settings/GeneralSettingsForm.vue";


export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BCardText,

    GeneralSettingsForm
  },
  data() {
    return {}
  },
}
</script>
