<template>
  <b-overlay :show="loading">
    <general-settings-card :general-settings="settings.general_settings" @update="updateSettings" />
    <security-settings-card :security-settings="settings.security_settings" @update="updateSettings" />
    <password-complexity-settings-card :password-complexity-settings="settings.password_complexity_settings" @update="updateSettings" />
  </b-overlay>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'

import {
  BOverlay, BRow, BCol, BCard, BCardBody, BButton, 
  BCardText, BFormTextarea, BFormCheckbox, BFormRadio, 
  BFormGroup, BPopover, VBToggle, BFormSpinbutton,
  BFormFile, BForm, BFormInput, BLink, BInputGroup
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import { required } from '@validations'
import { get, cloneDeep, pick } from "lodash"
import { quillEditor } from 'vue-quill-editor'
import {
  MUTATE_SETTINGS
} from "@/store/config/mutation-keys"

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import GeneralSettingsCard from "@core/components/shared/admin/settings/SettingCards/GeneralSettingsCard.vue";
import SecuritySettingsCard from "@core/components/shared/admin/settings/SettingCards/SecuritySettingsCard.vue";
import PasswordComplexitySettingsCard from "@core/components/shared/admin/settings/SettingCards/PasswordComplexitySettingsCard.vue";


export default {
  components: {
    BOverlay,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BPopover,
    vSelect,
    BFormTextarea,
    BFormCheckbox,
    BFormRadio,
    BFormGroup,
    BFormSpinbutton,
    BFormFile,
    BForm,
    BFormInput,
    BLink,
    BInputGroup,

    // validations
    ValidationProvider,
    ValidationObserver,
    quillEditor,
    GeneralSettingsCard,
    SecuritySettingsCard,
    PasswordComplexitySettingsCard,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  mixins: [heightTransition],
  props: {},
  data(){
    return {
      loading: false,
      errorMessage: '',
      settingsData: {},
      required,
      editorOption: {
        modules: {
            toolbar: [
              ['bold', 'italic', 'underline', 'strike'],
              [{ list: 'ordered' }, { list: 'bullet' }],
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              [{ align: [] }],
            ]
          },
        placeholder: "Enter a message to be shown to clients when loan module is disabled"
      },
      disableButton: false
    }
  },
  computed: {
    settings(){
      return this.$store.getters[`auth/settings`];
    },
    adminLoanTerms(){
      return this.getValueFromSource(this.settings, 'loan_terms', [])
    },
    canUpdateSettings(){
      return this.can("settings:CanUpdateGeneralSettings")
    },
    disableForm(){
      return !this.canUpdateSettings
    },
    disableLoanModuleMessageEditor() {
      if (this.disableForm) return this.disableForm;
      return this.settingsData.enable_loan_module
    },
    canRunUpdatePaidLoansStatusIntegrity(){
      return this.can('loan:CanUpdatePaidLoansStatusIntegrity');
    }
  },
  watch: {
    settings: {
      handler(settings){
        if (settings){
          this.settingsData = cloneDeep(settings)
        }
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    async updateSettings(update) {
      try {
        this.loading = true;
        this.errorMessage = '';

        const fields_allowed_for_update = [
          "loan_settings",
          "general_settings",
          "security_settings",
          "external_api_settings",
          "bank_details",
          "nss",
          "password_complexity_settings"
        ]
        
        const initial_settings = pick(this.settings, fields_allowed_for_update);
        const updates = pick(update, fields_allowed_for_update);
        const new_updates = {
          ...initial_settings,
          ...updates
        }

        const response = await this.useJwt().adminService.updateGeneralSettings(this.settings._id, new_updates);

        const settings = get(response, "data.data");
        this.$store.commit(`auth/${MUTATE_SETTINGS}`, settings);

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Succcess',
            icon: 'EditIcon',
            variant: 'success',
            text: "Settings updated successfully"
          },
        });
      } catch (error){
        const error_message = get(error, "response.data.message") || error.message
        this.errorMessage = error_message;

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    },
    async updatePaidLoansStatusIntegrity() {
      try {
        this.loading = true;
        await this.useJwt().adminService.updatePaidLoansStatusIntegrity();
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success',
            icon: 'EditIcon',
            variant: 'success',
            text: "Loans status integrity check queued successfully. Please give it some minute to see updates reflect."
          },
        });
        this.disableButton = true;
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        this.errorMessage = error_message;

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    },
    async onLoanTermDeselected(deselectedOption){
      try {
        if (!this.isUpdate){
          return;
        }

        const client_group_id = this.getValueFromSource(this.updatingClientGroup, '_id');
        const loan_term_id = deselectedOption._id;
        await this.useJwt().adminService.deleteClientGroupLoanTerm(client_group_id, loan_term_id)
        // Check if Loan Term can be removed.
      } catch (error){
        this.clientGroup.loan_terms.push(deselectedOption._id);
        const error_message = get(error, "response.data.message") || error.message
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async onRemoveCustomField(customField){
      try {
        this.loading = true;

        const deleteConfirmation = await this.$swal({
          title: 'Delete this custom field?',
          text: "This field will not be available for new registrations when removed. Existing registration will persist this field.",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })

        if (!deleteConfirmation.value){
          return;
        }

        const client_group_id = this.getValueFromSource(this.updatingClientGroup, '_id');
        const custom_field_id = customField._id;
        await this.useJwt().adminService.deleteClientGroupCustomField(client_group_id, custom_field_id);

        // Remove from current client group;
        const removedFieldIndex = this.clientGroup.custom_fields.map(cField => cField._id).indexOf(custom_field_id);
        this.clientGroup.custom_fields.splice(removedFieldIndex, 1);
        this.trTrimHeight(this.$refs.row[0].offsetHeight)
      } catch (error){
        const error_message = get(error, "response.data.message") || error.message
        this.errorMessage = error_message;

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    addNewCustomField(){
      const customField = cloneDeep(this.rawCustomField);
      this.nextFieldID += 1
      customField._id = this.nextFieldID

      this.clientGroup.custom_fields.push(customField)

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    initTrHeight() {
      this.trSetHeight(null)
    },
  }
}
</script>

<style lang="scss" scoped>
.quill-editor {
  height: 100px;
}
</style>
