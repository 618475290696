var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"clientGroupForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',[_c('h4',{staticClass:"settings-card-title"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('b-card',{staticClass:"px-2 py-2",attrs:{"no-body":""}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Enable Password Complexity Check","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"id":"enable_password_complexity_check","name":"enable_password_complexity_check"},model:{value:(_vm.settingsData.enable_password_complexity_check),callback:function ($$v) {_vm.$set(_vm.settingsData, "enable_password_complexity_check", $$v)},expression:"settingsData.enable_password_complexity_check"}},[_vm._v(" Enable Password Complexity Check ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(_vm.settingsData.enable_password_complexity_check)?_c('b-col',{staticClass:"ml-4"},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Use at least one uppercase and one lowercase letter","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"id":"include_upper_and_lower_cased_characters","name":"include_upper_and_lower_cased_characters"},model:{value:(_vm.settingsData.include_upper_and_lower_cased_characters),callback:function ($$v) {_vm.$set(_vm.settingsData, "include_upper_and_lower_cased_characters", $$v)},expression:"settingsData.include_upper_and_lower_cased_characters"}},[_vm._v(" Use at least one uppercase and one lowercase letter ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Include at least one number","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"id":"include_number","name":"include_number"},model:{value:(_vm.settingsData.include_number),callback:function ($$v) {_vm.$set(_vm.settingsData, "include_number", $$v)},expression:"settingsData.include_number"}},[_vm._v(" Include at least one number ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Include at least one special charater like @, $, !, etc."},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"id":"include_special_character","name":"include_special_character"},model:{value:(_vm.settingsData.include_special_character),callback:function ($$v) {_vm.$set(_vm.settingsData, "include_special_character", $$v)},expression:"settingsData.include_special_character"}},[_vm._v(" Include at least one special charater like @, $, !, etc. ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Minimum Length","label-for":"minimum_length"}},[_c('validation-provider',{attrs:{"name":"Minimum Length","rules":"required|integer|min_value:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"minimum_length","state":errors.length > 0 ? false : null,"size":"sm"},model:{value:(_vm.settingsData.minimum_length),callback:function ($$v) {_vm.$set(_vm.settingsData, "minimum_length", $$v)},expression:"settingsData.minimum_length"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1):_vm._e(),_c('div',[_c('b-button',{staticClass:"mt-2",attrs:{"variant":"primary","size":"lg","disabled":invalid},on:{"click":function($event){return _vm.$emit('update', { password_complexity_settings: _vm.settingsData })}}},[_vm._v(" Apply ")])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }