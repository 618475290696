var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"clientGroupForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',[_c('h4',{staticClass:"settings-card-title"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('b-card',{staticClass:"px-2 py-2",attrs:{"no-body":""}},[_c('b-form-group',{attrs:{"label-cols-sm":"4","label-cols-lg":"4","content-col-sm":"","content-cols-lg-7":""}},[_c('validation-provider',{attrs:{"name":"Enable Maintenance Mode","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"id":"enable_maintenance_mode","name":"enable_maintenance_mode"},model:{value:(_vm.settingsData.enable_maintenance_mode),callback:function ($$v) {_vm.$set(_vm.settingsData, "enable_maintenance_mode", $$v)},expression:"settingsData.enable_maintenance_mode"}},[_vm._v(" Enable Maintenance Mode ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-cols-sm":"4","label-cols-lg":"4","content-col-sm":"","content-cols-lg-7":""}},[_c('validation-provider',{attrs:{"name":"Disable New SignUps","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"id":"disable_new_signups","name":"disable_new_signups"},model:{value:(_vm.settingsData.disable_new_signups),callback:function ($$v) {_vm.$set(_vm.settingsData, "disable_new_signups", $$v)},expression:"settingsData.disable_new_signups"}},[_vm._v(" Disable New Sign-Ups ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-cols-sm":"4","label-cols-lg":"4","content-col-sm":"","content-cols-lg-7":""}},[_c('validation-provider',{attrs:{"name":"Enable Migrations","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"id":"enable_migrations","name":"enable_migrations"},model:{value:(_vm.settingsData.enable_migrations),callback:function ($$v) {_vm.$set(_vm.settingsData, "enable_migrations", $$v)},expression:"settingsData.enable_migrations"}},[_vm._v(" Enable Migrations ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',[_c('b-button',{attrs:{"variant":"primary","size":"lg","disabled":invalid},on:{"click":function($event){return _vm.$emit('update', { general_settings: _vm.settingsData })}}},[_vm._v(" Apply ")])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }